html {
}
 /* @font-face {
  font-family: 'Mont';
  src: url('../src/assets/fonts/mont/Mont-HeavyDEMO.otf') format('truetype');
  

} */
/* @font-face {
  font-family: 'Helvetica Neue';
  src: url('../src/assets/fonts/helvectica/HelveticaNeueCyr-Light.otf') format('truetype');

} */


/* @font-face {
  font-family: 'montsserat';
  src: url('../src/assets/fonts/montserat/Montserrat-VariableFont_wght.ttf') format('truetype');

}  */

body {
  margin: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

header{
  font-family:   'Helvetica Neue','Montsserat','Mont';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

