@import '../../assets/styles/mixin.scss';
@import '../../assets/styles//variables.scss';



.L-service-container {
	width: 100%;
	background-color: snow;
	height: auto;
	.background-icon-container{
		width: 100%;
		max-width: 1920px;
		background-repeat: no-repeat;
		background-position: left 100%;
		.L-service-wrapper {
			width: 100%;
			height: 100vh;
			justify-content: center;
			align-items: flex-start;
			padding-top: 20vh;
			@include M{
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				margin-top: -10vh;
			}
			@include S{
				min-height: 250vh;
				justify-content: flex-start;
			}
			.L-service-left-box {
				width: 30%;
				height: 100%;
				
				align-items: start;
				@include  M{
					width: 100%;
					height: auto;
					align-items: start;
					justify-content: center;
					text-align: center;
				}
				@include S{
					width: 100%;
					justify-content: start;
					text-align: start;
				}
				.L-text-container-service {
					flex-direction: column;
					@include M{
						justify-content: center;
						align-items: center;
						width: 100%;
					}
					@include S{
						justify-content: start;
						align-items: start;
						width: 100%;
					}
					
					.L-service-description {
						width: 95%;
						@include M{
			
						}
					}
	
					.L-logo-container-service {
						align-items: center;
						@include M{
							justify-content: center;
							flex-direction: column;
						}
						@include S{
							flex-direction: row;
						}
						.L-s {
							color: $blue;
						}
	
						.L-logo-box-service {
							animation-name: pulsation;
							animation-iteration-count: infinite;
							animation-duration: 2s;
							width: 20px;
							height: 35px;
						}
					}
				}
			}
	
			.L-service-right-box {	
				width: 55%;
				height: 80%;
				flex-wrap: wrap;
				@include S{
						height: 57%;
					width: 100%;
				}

				.L-card-hover-box {
					max-width: 400px;
					width: 50%;
					height: 45%;
					display: flex;
					justify-content: center;
					transition: transform 0.5s;
					
					
					@include S {
						width: 100%;
						height: 25%;
						max-width: none;
						
					}
					@media screen and (max-height:800px){
						margin: 0;
					}
					@include M{
						margin-top: 15px;
					}
				
					.card {
						width: 95%;
						height: 100%;
						border-radius: 8px;
						
						transition: transform 1s;
						transform-style: preserve-3d;
						transition: all 1s ease-in-out;
						cursor: pointer;
						transition: transform 0.3s;
						transform-style: preserve-3d;
						border-radius: 24px;
						transition:  0.5s ease all;
						
						
					}
	
					.card:hover {
						box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
					}
					.card.flipped {
						
					}
					.card .front,
					.card .back {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						padding: 20px;
						box-sizing: border-box;
						border-radius: 24px;
						color: black;
						-webkit-perspective: 0;
						-webkit-backface-visibility: hidden;
						visibility: visible;
						backface-visibility: hidden;
					}
	
					.card .front {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						background-color: #fff;
					}
	
					.card .back {
						height: 100%;
						background-color: grey;
						transform: rotateY(180deg);
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						z-index: 999;
						p{
							color: #ededed;
							font-size: 18px;
							@include S{
								font-size: 14px;
							}
						}
	
					}
	
					h2 {
						font-family: 'Montserrat';
						font-weight: bolder;
						font-size: 24px;
						color: black;
						text-align: center;
						@include S{
							font-size: 18px;
						}
					}
	
					.L-cube-back-side-icon {
						position: initial;
						width: 100px;
						height: 100px;
					}
	
					.L-cube-front-side-icon {
						position: initial;
						width: 80px;
						height: 80px;
					}
	
	
	
				}

			}	
		}
	}
}
