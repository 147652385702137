@import '../../assets/styles/mixin.scss';
@import '../../assets/styles/variables.scss';

.L-news-container {
	width: 100%;
	background-color: #4D5153;
	z-index: 9999;

	.L-news-wrapper {
		width: 100%;
		height: 100vh;
		justify-content: center;
		align-items: flex-start;
		padding-top: 20vh;

		@include M{
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		@include S{
			height: 200vh;
		}
		.L-txt-box {
			width: 30%;
			height: 100%;
			@include M{
				width: 100%;
				height: min-content;
				justify-content: center;
				align-items: center	;
			}
			@include S{
				width: 100%;
				justify-content: start;
				align-items: start;
			}
			.text-news-container{
				width: 100%;
				height: auto;
				display: flex;
				flex-direction: column;
				align-items: start;
				
				.L-news-logo {
					width: 100%;
					height: min-content;
					display: flex;
					align-items: center;
					@include M{
						flex-direction: column;
						justify-content: center;
					}
					@include S{
					flex-direction: row;
					justify-content: start;
					}
					.Pages-greeting{
						color: $blue;
					}
					
			
					.L-logo-box {
						animation-name: pulsation;
					animation-iteration-count: infinite;
					animation-duration: 2s;
					
						@keyframes pulsation {
							0% {
								transform: scale(0.5);
			
							}
			
							50% {
								transform: scale(0.8);
							}
			
							100% {
								transform: scale(0.5);
							}
						}
						.flash-svg {
							width: 25px;
							height: 30px;
						}
					}
				}
				.L-txt{
					width: auto;
					@include M{
						text-align: center;
					}
					@include S{
						text-align: start;
					}
					.news-description{
						width: 95%;
						color: snow;
						@include M{
							width: 100%;
							margin: 0;
							padding: 0;
						}
					}
					.Pages-Title{
						color: snow;
					}
				}
			}
		}
		.L-contain {
			width: 55%;
			height: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: start;
			@include M{
				min-height:70%;
				height: auto;
				justify-content: center;
				width: 80%;
			}
			@include S{
				height: 100%;
				width: 100%;
				
			}
			.L-data-container {
				width: 80%;
				height: 80%;
				display: flex;
				flex-direction: column;
				
				@include M{
					height: 100%;
					justify-content: space-evenly;

				}
				@include S{
					width: 100%;
				}
				.data-div{
					width: 100%;
					height: auto;
					min-height: 70%;
					display: flex;
					justify-content: space-between;
					@include S{
						flex-direction: column;
						width: 100%;
					}
					.L-box{
						width: 40%;
						height: 100%;
						color: snow;
						font-family: montserrat;
						p{
							font-size: small;
						}
						@include S{
							width: 100%;
						}
						.L-box-img{
							width: 100%;
							height: 50%;
							border-radius: 24px;
							padding:10px;
							&:hover {
								>.cirqle  {
								  transition: visibility 0.3s linear, opacity 0.3s linear;
								  visibility: visible;
								  opacity: 1;
								}
							  }
							
						}
					}
				}
				.button-div{
					width: 100%;
					height: 10%;
					display: flex;
					justify-content: flex-start;
					.select{
						width: 40%;
						height: 100%;
						background-color: $blue;
						border-radius: 32px;
						font-family: montserrat;
						font-weight: bolder;
						@include M{
							width: 100%;
						}
						@include S{
							min-height: 40px;
							max-height: 40px;
						}
					}
				}

			}
		}
	}
}