@import "./src/assets/styles/flex";
@import "./src/assets/styles/variables";
@import "./src/assets/styles/mixin";


.L-header-wrapper {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	height: 100px;
	
	@include M{
		height: 50px;
		backdrop-filter: blur(10px);
	}

	.flex-container{
		display: flex;
		align-items: center;
		.L-header-container {
			width: 100%;
			
	
			.L-mail-container {
				width: 45%;
	
				text-align: start;
				.L-links-container{
					display: none;
					
				@include M{
					display: block;
					width: 100%;
				}
				}
				.none{
					display: none;
				}
				.blok{
					display: flex!important;
					justify-content: space-between;
					width: 70%;
					display: block;
				}
	
				@include M {
					width: 100px;
					display: flex;
					justify-content: space-between;
				}
	
				.gmail {
					color: #707070;
					font-family: 'Montserrat';
					font-size: 18px;
					opacity: 0.5;
					@include M {
						display: none;
					}
				}
	
				.blackcolor {
					color: black;
				}
	
				.whitecolor {
					color: rgba(255, 255, 255, 0.471);
				}
			}
	
			.L-logo-container {
				width: fit-content;
				img{
					
				}
				.L-logo {
					
					height: 30px;
					@include M{
						width: fit-content;
					
					}
					.logo-link{
						width: 170px;
					}
					@include M{
						
					
					}
					.none {
						display: none;
					}
	
					.blok {
						display: block;
						margin: 0;
						@include M {
							height: 22px;
							
						}
					}
				}
			}
			.L-nav-container {
				display: flex;
				justify-content: end;
				align-items: center;
				z-index: 999;
				width: 45%;
				@include M{
					display: block;
					width: fit-content;
				}
				.Hamburger{
					display: none;
					
					
					@include M {
						display: block;
						background-color: transparent;
					}
					.hamburger-icon{
						width: fit-content;
						transform: rotate(90deg);
					
					.columns{
						font-size: 20px;
					}
					}
				}
				.white{
					background-color: transparent;
					color: snow;
					
				}
				.black{
					background-color: transparent;
					
					color: grey;
				}
				
	
				.L-nav-box {
					@include M {
						display: none;
					}
	
					li {
						position: relative;
						margin-left: 40px;
	
						a {
							color: #707070;
							font-family: 'Montserrat';
							font-size: 18px;
							text-decoration: none;
							
							&:active {
								color: $blue;
							}
							@include XS{
								font-size: 14px;
							}

						}
	
						.active::before {
							content: '';
							position: absolute;
							top: 0%;
								left: -20px;
								width: 15px;
								height: 15px;
							background-image: url('../../assets/images/flesh.png');
							background-position: center;
							background-repeat: no-repeat;
							background-size: contain;
	
	
	
						}
	
						.active {
							font-family: montsserat, sans-serif;
						}
						@include XS{
							margin-left: 25px;
						}
					}
	
				}
	
				.blackcolor {
					li {
						.links {
							color: black;
							font-family: 'Montserrat';
						}
					}
				}
	
				.whitecolor {
					li {
						.links {
							color: #ffff;
							font-family: 'Montserrat';
						}
					}
				}
	
				//mobile
	
				.L-mobile-nav {
					display: none;
					background-color: $blue;
					width: 100%;
					height: 100vh;
					position: absolute;
					top: 50px;
					left: 0;
					display: flex;
					flex-direction: column;
					justify-content: center;
					text-align: center;
					padding: 15px;
				
					@include M{
						display: block;
						ul {
							width: auto;
							height: 70%;
							text-align: center;
							display: flex;
							flex-direction: column;
							justify-content: start;
							align-items:center;
							
							
						
		
							li {
								
								font-size: 24px;
								font-weight: 500;
								line-height: 72px;
								height: fit-content;
								a{
	
									width: 100%;
									display: flex;
									justify-content: end;
									align-items: center;
									text-align: center;
									font-family: "Montserrat";
									
									
								}
								.active::before{
									content: '';
									position: relative;
									top: 0;
									width: 15px;
									height: 15px;
									background-image: url('../../assets/images/flesh.png');
									background-position: center;
									background-repeat: no-repeat;
									background-size: contain;
								}
						
							}
							
						}
	
					}
					.infoBeetee{
						font-family: montserrat;
					}
					
	
	
				}
	
			}
		}

	}
}
.blueheader{
	background-color:$blue;
}