@import "variables";



article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
label {
  display : block;
}

ol,
ul {
  list-style : none;
  padding    : 0;
  margin     : 0;
}

table {
  width           : 100%;
  border-collapse : collapse;
  border-spacing  : 0;
}



input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance : none;
  -moz-appearance    : none;
  appearance         : none;
}

a {
  display         : inline-block;
  text-decoration : none;
}

button {
  cursor   : pointer;
  position : relative;
}

input,
button,
select,
textarea {
  display : inline-block;
  padding : 0;
  border  : 0;
}

svg {
  width  : 100%;
  height : 100%;
}

img {
  max-width  : 100%;
  max-height : 100%;
}


