@import '../../../assets/styles/mixin.scss';
@import '../../../assets/styles/variables.scss';



.L-blog-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffff;
height: auto;

  .inside-wrapper-news {
    width: 100%;
    height: auto;
    background-color: #ffff;
    min-height: 100vh;
    height: auto;
   
    @include L {
      flex-direction: column;
      padding-top: 20%;
    }

    .L-menu {
      height: 100%;
      width: 30%;
      position: fixed;
      top: 17%;
      display: flex;
      justify-content: center;
      @include L {
        position: initial;
        width: 100%;
        height: 200px;

      }

      .text-container {
        width: 50%;
        height: 50%;

        @include L {
          height: 100%;
          width: 100%;


        }

        .Pages-greeting {
          color: $blue;
        }

        .cursor {
          cursor: pointer;

          .back-arrow {
            width: 25px;
            height: 15px;

          }

        }
      }
    }

    .L-data-body {
      width: 65%;
      height: auto;
      position: relative;
      left: 30%;
      top: 17vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

   
      @include L {
        width: 100%;
        left: 0;
        align-items: center;
      }

     
      .flexebl{
        width: 100%;
        height: 100%;
        @include M{
          display: block;
          justify-content: center;
        }
        .L-body-box {
          width: 60%;
          height: 70vh;
  
          @include L {
            width: 90%;
            height: auto;
          }
          @include M{
            margin-top: 25px;
            margin-bottom: 25px;
          }
  
          .L-box {
            height: 100%;
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
              @include M{
                height: 50%;
              }
            .L-box-title {
              
              font-family: Mont;
            }
  
            .imgs {
              border-radius: 24px;
              width: 100%;
              background-size: cover;
              padding: 10px;
              background-repeat: no-repeat;
              height: 50%;
              min-height: 300px;
              @include M{
                height: 200px;
                min-height: 200px;
              }
  
              img {
                width: 100%;
              }
  
              .start-cirqle {}
  
              @include S {
                top: 90%;
  
              }
  
              &:hover {
                >.start-cirqle {
                  transition: visibility 0.3s linear, opacity 0.3s linear;
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
 
    }
  }
}