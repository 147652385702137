@import "variables";
@import "mixin";


.G-cursor-pointer {
  cursor : pointer;
}
.G-text-align{
  text-align: center;
}
.G-text-align-start{
  text-align: start;
}
.G-background-100{
  background-position : center;
  background-repeat : no-repeat;
  background-size : 100%;
}
.G-background-cover{
  background-position : center;
  background-repeat : no-repeat;
  background-size : cover;
}
.G-background-contain{
  background-position : center;
  background-repeat : no-repeat;
  background-size : contain;
}


/* Handle */


.G-padding-15 {
  padding : 15px;
}
.G-container {
  width      : 95%;
  max-width  : 1816px;
  margin     : 0 auto;
  
 


}
.G-box-container{
  width: 95%;
  margin: 0 auto;

}
.G-button-color {

  &:hover {
	transition : 0.5s;
  }
}
.G-position-sticky{
  position: sticky;
  top: 0;
}
.G-disable-sticky{
  position: initial;
}
.G-input-radio {
  border-radius    : 10px;
  cursor           : pointer;
  height           : 15px;
  margin-right     : 15px;
  position         : relative;
  width            : 15px;

  &:after {
	border-radius    : 25px;
	content          : '';
	display          : block;
	height           : 7px;
	left             : 4px;
	position         : relative;
	top              : 4px;
	width            : 7px;
  }

  &:checked:after {

  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;



}



@font-face {
  src: url(../fonts/helvectica/HelveticaNeueCyr-Light.otf);
  font-family: Helvetica Neue;
  font-display: swap;
}

@font-face {
  src: url(../fonts//montserat/Montserrat-VariableFont_wght.ttf);
  font-family: montserrat;
  font-display: swap;
}
@font-face{
  src: url('../fonts/mont/Mont-HeavyDEMO.otf');
  font-family: Mont;
}

a {
  text-decoration: none;
  color: aliceblue;
}


::-webkit-scrollbar {
  width: 0px;
  height: 0px;

}


.Pages-Title{
  font-size: 58px;
  font-family: 'mont' ;
  margin: 15px 0;

  @include M {
    font-size: 40px;
    margin: 5px 0;
  }
}
.Pages-description{
  font-family:'Montserrat';
  font-size: 18px;
  width: 80%;
  @include M {
    font-size: 16px;
  }
}
.Pages-greeting{
  font-family: Helvetica Neue;
  font-size: 20px;
  @include M {
    font-size: 14px;
  }
}


