.st0 {
    fill: #FFFFFF;
    stroke: #000000;
    stroke-width: 1.0548;
    stroke-miterlimit: 10;
}

.st1 {
    opacity: 0.7;
}

.st2 {
    fill: #2C2E3C;
}

.st3 {
    fill: #01A2B0;
}

.st4 {
    opacity: 0.1;
    fill: #AFAFB6;
}

.st5 {
    fill: none;
    stroke: #01A2B0;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-dasharray: 9, 9, 9, 9, 9, 9;
}

.st6 {
    opacity: 0.7;
    fill: #2C2E3C;
}

.st7 {
    fill: #363848;
    stroke: #2C2E3C;
    stroke-miterlimit: 10;
}

.st8 {
    fill: #363848;
}

.st9 {
    opacity: 0.23;
    fill: #01A2B0;
}

.st10 {
    opacity: 0.5;
    fill: #01A2B0;
}

.st11 {
    fill: #02A2AF;
}

.st12 {
    opacity: 0.5;
    fill: #02A2AF;
}

.st13 {
    fill: #AFAFB6;
}

.st14 {
    opacity: 5.000000e-02;
    fill: #333333;
    enable-background: new;
}

.st15 {
    fill: #F4F4F4;
}

.st16 {
    fill: #FFFFFF;
}

.st17 {
    opacity: 0.23;
    fill: #01A2B0;
    enable-background: new;
}

.st18 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #01A2B0;
}

.st19 {
    opacity: 0.7;
    fill: #FFFFFF;
    enable-background: new;
}

.st20 {
    fill: #F4F4F4;
    stroke: #FFFFFF;
    stroke-miterlimit: 10;
}

.st21 {
    opacity: 0.5;
    fill: #01A2B0;
    enable-background: new;
}

.st22 {
    opacity: 0.7;
    fill: #2C2E3C;
    enable-background: new;
}

.st23 {
    fill: #353748;
}

.st24 {
    opacity: 0.5;
    fill: #363848;
}

.st25 {
    opacity: 0.7;
    enable-background: new;
}

.st26 {
    fill: #2C2E3C;
    stroke: #01A2B0;
    stroke-width: 5.1716;
    stroke-miterlimit: 10;
}

.st27 {
    fill: none;
    stroke: #01A2B0;
    stroke-width: 5.4773;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.st28 {
    fill: #363848;
    stroke: #2C2E3C;
    stroke-width: 0.9876;
    stroke-miterlimit: 10;
}

.st29 {
    opacity: 5.000000e-02;
    enable-background: new;
}

.st30 {
    opacity: 0.5;
    fill: #333333;
}

.st31 {
    fill: #333333;
}

.st32 {
    fill: #07A3AF;
}

.st33 {
    opacity: 0.1;
    fill: #B0B0B7;
    enable-background: new;
}

.st34 {
    opacity: 0.5;
    fill: #07A3AF;
    enable-background: new;
}

.st35 {
    fill: #F4F4F4;
    stroke: #FFFFFF;
    stroke-width: 2.0764;
    stroke-miterlimit: 10;
}

.st36 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #FFFFFF;
}

.st37 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #07A3AF;
}

.st38 {
    fill: none;
    stroke: #01A2B0;
    stroke-width: 7;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.st39 {
    fill: #F4F4F4;
    stroke: #FFFFFF;
    stroke-width: 2.7071;
    stroke-miterlimit: 10;
}

.st40 {
    enable-background: new;
}

.st41 {
    fill: none;
    stroke: #01A2B0;
    stroke-miterlimit: 10;
}

.st42 {
    opacity: 0.26;
}

.st43 {
    fill: none;
    stroke: #48A3DA;
    stroke-miterlimit: 10;
}

.st44 {
    fill: #48A3DA;
}

.st45 {
    fill: #707071;
}

.st46 {
    fill: #F8C911;
}



.IWcSNwFC_1 {
    stroke-dasharray: 226 228;
    stroke-dashoffset: 227;
    animation: IWcSNwFC_draw 2000ms linear 8ms forwards;
}

.IWcSNwFC_2 {
    stroke-dasharray: 744 746;
    stroke-dashoffset: 745;
    animation: IWcSNwFC_draw 2000ms linear 17ms forwards;
}

.IWcSNwFC_3 {
    stroke-dasharray: 243 245;
    stroke-dashoffset: 244;
    animation: IWcSNwFC_draw 2000ms linear 26ms forwards;
}

.IWcSNwFC_4 {
    stroke-dasharray: 1580 1582;
    stroke-dashoffset: 1581;
    animation: IWcSNwFC_draw 2000ms linear 35ms forwards;
}

.IWcSNwFC_5 {
    stroke-dasharray: 465 467;
    stroke-dashoffset: 466;
    animation: IWcSNwFC_draw 2000ms linear 44ms forwards;
}

.IWcSNwFC_6 {
    stroke-dasharray: 391 393;
    stroke-dashoffset: 392;
    animation: IWcSNwFC_draw 2000ms linear 53ms forwards;
}

.IWcSNwFC_7 {
    stroke-dasharray: 775 777;
    stroke-dashoffset: 776;
    animation: IWcSNwFC_draw 2000ms linear 62ms forwards;
}

.IWcSNwFC_8 {
    stroke-dasharray: 598 600;
    stroke-dashoffset: 599;
    animation: IWcSNwFC_draw 2000ms linear 71ms forwards;
}

.IWcSNwFC_9 {
    stroke-dasharray: 373 375;
    stroke-dashoffset: 374;
    animation: IWcSNwFC_draw 2000ms linear 80ms forwards;
}

.IWcSNwFC_10 {
    stroke-dasharray: 244 246;
    stroke-dashoffset: 245;
    animation: IWcSNwFC_draw 2000ms linear 89ms forwards;
}

.IWcSNwFC_11 {
    stroke-dasharray: 375 377;
    stroke-dashoffset: 376;
    animation: IWcSNwFC_draw 2000ms linear 98ms forwards;
}

.IWcSNwFC_12 {
    stroke-dasharray: 394 396;
    stroke-dashoffset: 395;
    animation: IWcSNwFC_draw 2000ms linear 107ms forwards;
}

.IWcSNwFC_13 {
    stroke-dasharray: 93 95;
    stroke-dashoffset: 94;
    animation: IWcSNwFC_draw 2000ms linear 116ms forwards;
}

.IWcSNwFC_14 {
    stroke-dasharray: 230 232;
    stroke-dashoffset: 231;
    animation: IWcSNwFC_draw 2000ms linear 125ms forwards;
}

.IWcSNwFC_15 {
    stroke-dasharray: 233 235;
    stroke-dashoffset: 234;
    animation: IWcSNwFC_draw 2000ms linear 133ms forwards;
}

.IWcSNwFC_16 {
    stroke-dasharray: 196 198;
    stroke-dashoffset: 197;
    animation: IWcSNwFC_draw 2000ms linear 142ms forwards;
}

.IWcSNwFC_17 {
    stroke-dasharray: 202 204;
    stroke-dashoffset: 203;
    animation: IWcSNwFC_draw 2000ms linear 151ms forwards;
}

.IWcSNwFC_18 {
    stroke-dasharray: 73 75;
    stroke-dashoffset: 74;
    animation: IWcSNwFC_draw 2000ms linear 160ms forwards;
}

.IWcSNwFC_19 {
    stroke-dasharray: 106 108;
    stroke-dashoffset: 107;
    animation: IWcSNwFC_draw 2000ms linear 169ms forwards;
}

.IWcSNwFC_20 {
    stroke-dasharray: 90 92;
    stroke-dashoffset: 91;
    animation: IWcSNwFC_draw 2000ms linear 178ms forwards;
}

.IWcSNwFC_21 {
    stroke-dasharray: 269 271;
    stroke-dashoffset: 270;
    animation: IWcSNwFC_draw 2000ms linear 187ms forwards;
}

.IWcSNwFC_22 {
    stroke-dasharray: 442 444;
    stroke-dashoffset: 443;
    animation: IWcSNwFC_draw 2000ms linear 196ms forwards;
}

.IWcSNwFC_23 {
    stroke-dasharray: 224 226;
    stroke-dashoffset: 225;
    animation: IWcSNwFC_draw 2000ms linear 205ms forwards;
}

.IWcSNwFC_24 {
    stroke-dasharray: 37 39;
    stroke-dashoffset: 38;
    animation: IWcSNwFC_draw 2000ms linear 214ms forwards;
}

.IWcSNwFC_25 {
    stroke-dasharray: 40 42;
    stroke-dashoffset: 41;
    animation: IWcSNwFC_draw 2000ms linear 223ms forwards;
}

.IWcSNwFC_26 {
    stroke-dasharray: 22 24;
    stroke-dashoffset: 23;
    animation: IWcSNwFC_draw 2000ms linear 232ms forwards;
}

.IWcSNwFC_27 {
    stroke-dasharray: 24 26;
    stroke-dashoffset: 25;
    animation: IWcSNwFC_draw 2000ms linear 241ms forwards;
}

.IWcSNwFC_28 {
    stroke-dasharray: 48 50;
    stroke-dashoffset: 49;
    animation: IWcSNwFC_draw 2000ms linear 250ms forwards;
}

.IWcSNwFC_29 {
    stroke-dasharray: 42 44;
    stroke-dashoffset: 43;
    animation: IWcSNwFC_draw 2000ms linear 258ms forwards;
}

.IWcSNwFC_30 {
    stroke-dasharray: 51 53;
    stroke-dashoffset: 52;
    animation: IWcSNwFC_draw 2000ms linear 267ms forwards;
}

.IWcSNwFC_31 {
    stroke-dasharray: 42 44;
    stroke-dashoffset: 43;
    animation: IWcSNwFC_draw 2000ms linear 276ms forwards;
}

.IWcSNwFC_32 {
    stroke-dasharray: 55 57;
    stroke-dashoffset: 56;
    animation: IWcSNwFC_draw 2000ms linear 285ms forwards;
}

.IWcSNwFC_33 {
    stroke-dasharray: 50 52;
    stroke-dashoffset: 51;
    animation: IWcSNwFC_draw 2000ms linear 294ms forwards;
}

.IWcSNwFC_34 {
    stroke-dasharray: 24 26;
    stroke-dashoffset: 25;
    animation: IWcSNwFC_draw 2000ms linear 303ms forwards;
}

.IWcSNwFC_35 {
    stroke-dasharray: 65 67;
    stroke-dashoffset: 66;
    animation: IWcSNwFC_draw 2000ms linear 312ms forwards;
}

.IWcSNwFC_36 {
    stroke-dasharray: 48 50;
    stroke-dashoffset: 49;
    animation: IWcSNwFC_draw 2000ms linear 321ms forwards;
}

.IWcSNwFC_37 {
    stroke-dasharray: 24 26;
    stroke-dashoffset: 25;
    animation: IWcSNwFC_draw 2000ms linear 330ms forwards;
}

.IWcSNwFC_38 {
    stroke-dasharray: 49 51;
    stroke-dashoffset: 50;
    animation: IWcSNwFC_draw 2000ms linear 339ms forwards;
}

.IWcSNwFC_39 {
    stroke-dasharray: 50 52;
    stroke-dashoffset: 51;
    animation: IWcSNwFC_draw 2000ms linear 348ms forwards;
}

.IWcSNwFC_40 {
    stroke-dasharray: 32 34;
    stroke-dashoffset: 33;
    animation: IWcSNwFC_draw 2000ms linear 357ms forwards;
}

.IWcSNwFC_41 {
    stroke-dasharray: 73 75;
    stroke-dashoffset: 74;
    animation: IWcSNwFC_draw 2000ms linear 366ms forwards;
}

.IWcSNwFC_42 {
    stroke-dasharray: 43 45;
    stroke-dashoffset: 44;
    animation: IWcSNwFC_draw 2000ms linear 375ms forwards;
}

.IWcSNwFC_43 {
    stroke-dasharray: 42 44;
    stroke-dashoffset: 43;
    animation: IWcSNwFC_draw 2000ms linear 383ms forwards;
}

.IWcSNwFC_44 {
    stroke-dasharray: 50 52;
    stroke-dashoffset: 51;
    animation: IWcSNwFC_draw 2000ms linear 392ms forwards;
}

.IWcSNwFC_45 {
    stroke-dasharray: 45 47;
    stroke-dashoffset: 46;
    animation: IWcSNwFC_draw 2000ms linear 401ms forwards;
}

.IWcSNwFC_46 {
    stroke-dasharray: 53 55;
    stroke-dashoffset: 54;
    animation: IWcSNwFC_draw 2000ms linear 410ms forwards;
}

.IWcSNwFC_47 {
    stroke-dasharray: 24 26;
    stroke-dashoffset: 25;
    animation: IWcSNwFC_draw 2000ms linear 419ms forwards;
}

.IWcSNwFC_48 {
    stroke-dasharray: 48 50;
    stroke-dashoffset: 49;
    animation: IWcSNwFC_draw 2000ms linear 428ms forwards;
}

.IWcSNwFC_49 {
    stroke-dasharray: 65 67;
    stroke-dashoffset: 66;
    animation: IWcSNwFC_draw 2000ms linear 437ms forwards;
}

.IWcSNwFC_50 {
    stroke-dasharray: 50 52;
    stroke-dashoffset: 51;
    animation: IWcSNwFC_draw 2000ms linear 446ms forwards;
}

.IWcSNwFC_51 {
    stroke-dasharray: 22 24;
    stroke-dashoffset: 23;
    animation: IWcSNwFC_draw 2000ms linear 455ms forwards;
}

.IWcSNwFC_52 {
    stroke-dasharray: 65 67;
    stroke-dashoffset: 66;
    animation: IWcSNwFC_draw 2000ms linear 464ms forwards;
}

.IWcSNwFC_53 {
    stroke-dasharray: 43 45;
    stroke-dashoffset: 44;
    animation: IWcSNwFC_draw 2000ms linear 473ms forwards;
}

.IWcSNwFC_54 {
    stroke-dasharray: 41 43;
    stroke-dashoffset: 42;
    animation: IWcSNwFC_draw 2000ms linear 482ms forwards;
}

.IWcSNwFC_55 {
    stroke-dasharray: 45 47;
    stroke-dashoffset: 46;
    animation: IWcSNwFC_draw 2000ms linear 491ms forwards;
}

.IWcSNwFC_56 {
    stroke-dasharray: 24 26;
    stroke-dashoffset: 25;
    animation: IWcSNwFC_draw 2000ms linear 500ms forwards;
}

.IWcSNwFC_57 {
    stroke-dasharray: 48 50;
    stroke-dashoffset: 49;
    animation: IWcSNwFC_draw 2000ms linear 508ms forwards;
}

.IWcSNwFC_58 {
    stroke-dasharray: 32 34;
    stroke-dashoffset: 33;
    animation: IWcSNwFC_draw 2000ms linear 517ms forwards;
}

.IWcSNwFC_59 {
    stroke-dasharray: 41 43;
    stroke-dashoffset: 42;
    animation: IWcSNwFC_draw 2000ms linear 526ms forwards;
}

.IWcSNwFC_60 {
    stroke-dasharray: 45 47;
    stroke-dashoffset: 46;
    animation: IWcSNwFC_draw 2000ms linear 535ms forwards;
}

.IWcSNwFC_61 {
    stroke-dasharray: 48 50;
    stroke-dashoffset: 49;
    animation: IWcSNwFC_draw 2000ms linear 544ms forwards;
}

.IWcSNwFC_62 {
    stroke-dasharray: 41 43;
    stroke-dashoffset: 42;
    animation: IWcSNwFC_draw 2000ms linear 553ms forwards;
}

.IWcSNwFC_63 {
    stroke-dasharray: 50 52;
    stroke-dashoffset: 51;
    animation: IWcSNwFC_draw 2000ms linear 562ms forwards;
}

.IWcSNwFC_64 {
    stroke-dasharray: 32 34;
    stroke-dashoffset: 33;
    animation: IWcSNwFC_draw 2000ms linear 571ms forwards;
}

.IWcSNwFC_65 {
    stroke-dasharray: 6 8;
    stroke-dashoffset: 7;
    animation: IWcSNwFC_draw 2000ms linear 580ms forwards;
}

.IWcSNwFC_66 {
    stroke-dasharray: 51 53;
    stroke-dashoffset: 52;
    animation: IWcSNwFC_draw 2000ms linear 589ms forwards;
}

.IWcSNwFC_67 {
    stroke-dasharray: 22 24;
    stroke-dashoffset: 23;
    animation: IWcSNwFC_draw 2000ms linear 598ms forwards;
}

.IWcSNwFC_68 {
    stroke-dasharray: 65 67;
    stroke-dashoffset: 66;
    animation: IWcSNwFC_draw 2000ms linear 607ms forwards;
}

.IWcSNwFC_69 {
    stroke-dasharray: 43 45;
    stroke-dashoffset: 44;
    animation: IWcSNwFC_draw 2000ms linear 616ms forwards;
}

.IWcSNwFC_70 {
    stroke-dasharray: 41 43;
    stroke-dashoffset: 42;
    animation: IWcSNwFC_draw 2000ms linear 625ms forwards;
}

.IWcSNwFC_71 {
    stroke-dasharray: 45 47;
    stroke-dashoffset: 46;
    animation: IWcSNwFC_draw 2000ms linear 633ms forwards;
}

.IWcSNwFC_72 {
    stroke-dasharray: 24 26;
    stroke-dashoffset: 25;
    animation: IWcSNwFC_draw 2000ms linear 642ms forwards;
}

.IWcSNwFC_73 {
    stroke-dasharray: 49 51;
    stroke-dashoffset: 50;
    animation: IWcSNwFC_draw 2000ms linear 651ms forwards;
}

.IWcSNwFC_74 {
    stroke-dasharray: 33 35;
    stroke-dashoffset: 34;
    animation: IWcSNwFC_draw 2000ms linear 660ms forwards;
}

.IWcSNwFC_75 {
    stroke-dasharray: 41 43;
    stroke-dashoffset: 42;
    animation: IWcSNwFC_draw 2000ms linear 669ms forwards;
}

.IWcSNwFC_76 {
    stroke-dasharray: 45 47;
    stroke-dashoffset: 46;
    animation: IWcSNwFC_draw 2000ms linear 678ms forwards;
}

.IWcSNwFC_77 {
    stroke-dasharray: 49 51;
    stroke-dashoffset: 50;
    animation: IWcSNwFC_draw 2000ms linear 687ms forwards;
}

.IWcSNwFC_78 {
    stroke-dasharray: 41 43;
    stroke-dashoffset: 42;
    animation: IWcSNwFC_draw 2000ms linear 696ms forwards;
}

.IWcSNwFC_79 {
    stroke-dasharray: 51 53;
    stroke-dashoffset: 52;
    animation: IWcSNwFC_draw 2000ms linear 705ms forwards;
}

.IWcSNwFC_80 {
    stroke-dasharray: 33 35;
    stroke-dashoffset: 34;
    animation: IWcSNwFC_draw 2000ms linear 714ms forwards;
}

.IWcSNwFC_81 {
    stroke-dasharray: 5 7;
    stroke-dashoffset: 6;
    animation: IWcSNwFC_draw 2000ms linear 723ms forwards;
}

.IWcSNwFC_82 {
    stroke-dasharray: 55 57;
    stroke-dashoffset: 56;
    animation: IWcSNwFC_draw 2000ms linear 732ms forwards;
}

.IWcSNwFC_83 {
    stroke-dasharray: 48 50;
    stroke-dashoffset: 49;
    animation: IWcSNwFC_draw 2000ms linear 741ms forwards;
}

.IWcSNwFC_84 {
    stroke-dasharray: 32 34;
    stroke-dashoffset: 33;
    animation: IWcSNwFC_draw 2000ms linear 750ms forwards;
}

.IWcSNwFC_85 {
    stroke-dasharray: 48 50;
    stroke-dashoffset: 49;
    animation: IWcSNwFC_draw 2000ms linear 758ms forwards;
}

.IWcSNwFC_86 {
    stroke-dasharray: 40 42;
    stroke-dashoffset: 41;
    animation: IWcSNwFC_draw 2000ms linear 767ms forwards;
}

.IWcSNwFC_87 {
    stroke-dasharray: 42 44;
    stroke-dashoffset: 43;
    animation: IWcSNwFC_draw 2000ms linear 776ms forwards;
}

.IWcSNwFC_88 {
    stroke-dasharray: 41 43;
    stroke-dashoffset: 42;
    animation: IWcSNwFC_draw 2000ms linear 785ms forwards;
}

.IWcSNwFC_89 {
    stroke-dasharray: 42 44;
    stroke-dashoffset: 43;
    animation: IWcSNwFC_draw 2000ms linear 794ms forwards;
}

.IWcSNwFC_90 {
    stroke-dasharray: 23 25;
    stroke-dashoffset: 24;
    animation: IWcSNwFC_draw 2000ms linear 803ms forwards;
}

.IWcSNwFC_91 {
    stroke-dasharray: 51 53;
    stroke-dashoffset: 52;
    animation: IWcSNwFC_draw 2000ms linear 812ms forwards;
}

.IWcSNwFC_92 {
    stroke-dasharray: 32 34;
    stroke-dashoffset: 33;
    animation: IWcSNwFC_draw 2000ms linear 821ms forwards;
}

.IWcSNwFC_93 {
    stroke-dasharray: 6 8;
    stroke-dashoffset: 7;
    animation: IWcSNwFC_draw 2000ms linear 830ms forwards;
}

.IWcSNwFC_94 {
    stroke-dasharray: 55 57;
    stroke-dashoffset: 56;
    animation: IWcSNwFC_draw 2000ms linear 839ms forwards;
}

.IWcSNwFC_95 {
    stroke-dasharray: 48 50;
    stroke-dashoffset: 49;
    animation: IWcSNwFC_draw 2000ms linear 848ms forwards;
}

.IWcSNwFC_96 {
    stroke-dasharray: 32 34;
    stroke-dashoffset: 33;
    animation: IWcSNwFC_draw 2000ms linear 857ms forwards;
}

.IWcSNwFC_97 {
    stroke-dasharray: 48 50;
    stroke-dashoffset: 49;
    animation: IWcSNwFC_draw 2000ms linear 866ms forwards;
}

.IWcSNwFC_98 {
    stroke-dasharray: 40 42;
    stroke-dashoffset: 41;
    animation: IWcSNwFC_draw 2000ms linear 875ms forwards;
}

.IWcSNwFC_99 {
    stroke-dasharray: 42 44;
    stroke-dashoffset: 43;
    animation: IWcSNwFC_draw 2000ms linear 883ms forwards;
}

.IWcSNwFC_100 {
    stroke-dasharray: 42 44;
    stroke-dashoffset: 43;
    animation: IWcSNwFC_draw 2000ms linear 892ms forwards;
}

.IWcSNwFC_101 {
    stroke-dasharray: 42 44;
    stroke-dashoffset: 43;
    animation: IWcSNwFC_draw 2000ms linear 901ms forwards;
}

.IWcSNwFC_102 {
    stroke-dasharray: 22 24;
    stroke-dashoffset: 23;
    animation: IWcSNwFC_draw 2000ms linear 910ms forwards;
}

.IWcSNwFC_103 {
    stroke-dasharray: 51 53;
    stroke-dashoffset: 52;
    animation: IWcSNwFC_draw 2000ms linear 919ms forwards;
}

.IWcSNwFC_104 {
    stroke-dasharray: 32 34;
    stroke-dashoffset: 33;
    animation: IWcSNwFC_draw 2000ms linear 928ms forwards;
}

.IWcSNwFC_105 {
    stroke-dasharray: 5 7;
    stroke-dashoffset: 6;
    animation: IWcSNwFC_draw 2000ms linear 937ms forwards;
}

.IWcSNwFC_106 {
    stroke-dasharray: 48 50;
    stroke-dashoffset: 49;
    animation: IWcSNwFC_draw 2000ms linear 946ms forwards;
}

.IWcSNwFC_107 {
    stroke-dasharray: 33 35;
    stroke-dashoffset: 34;
    animation: IWcSNwFC_draw 2000ms linear 955ms forwards;
}

.IWcSNwFC_108 {
    stroke-dasharray: 28 30;
    stroke-dashoffset: 29;
    animation: IWcSNwFC_draw 2000ms linear 964ms forwards;
}

.IWcSNwFC_109 {
    stroke-dasharray: 111 113;
    stroke-dashoffset: 112;
    animation: IWcSNwFC_draw 2000ms linear 973ms forwards;
}

.IWcSNwFC_110 {
    stroke-dasharray: 66 68;
    stroke-dashoffset: 67;
    animation: IWcSNwFC_draw 2000ms linear 982ms forwards;
}

.IWcSNwFC_111 {
    stroke-dasharray: 34 36;
    stroke-dashoffset: 35;
    animation: IWcSNwFC_draw 2000ms linear 991ms forwards;
}

.IWcSNwFC_112 {
    stroke-dasharray: 23 25;
    stroke-dashoffset: 24;
    animation: IWcSNwFC_draw 2000ms linear 1000ms forwards;
}

@keyframes IWcSNwFC_draw {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes IWcSNwFC_fade {
    0% {
        stroke-opacity: 1;
    }

    94.44444444444444% {
        stroke-opacity: 1;
    }

    100% {
        stroke-opacity: 0;
    }
}

