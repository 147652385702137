@import '../../assets/styles/mixin.scss';
@import '../../assets/styles/variables.scss';
@import '../../assets/styles/normalize.scss';


.modal {
    position: fixed;
    z-index: 9999;
    animation: popup;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    width: 100%;
    height: 100vh;
    visibility: hidden;
    top: 0;
    left: 0;

    @keyframes popup {

        0% {

            visibility: visible;
        }

        100% {

            visibility: visible;
            background-color: rgba(128, 128, 128, 0.301);
        }

    }

    .overviev {
        animation: popupviev;
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
        visibility: hidden;
        perspective: 3000px;

        @keyframes popupviev {
            0% {
                width: 0;
                height: 0;
                visibility: hidden;
            }

            50% {
                width: 100%;
                height: 100%;

            }

            100% {
                width: 100%;
                height: 100%;
                visibility: visible;
            }

        }

        .popup {
            width: 50%;
            height: 70%;
            background-color: snow;
            animation: modal;
            animation-fill-mode: forwards;
            border-radius: 24px;
            animation-duration: 0.3s;
            transition: all 0.5s ease-in-out;
            transform-style: preserve-3d;
            position: relative;
            color: black;
            font-family: montserrat;
            text-align: center;
            overflow-y: auto;
            max-height: 70%;
           
            @include S {
                width: 90%;
                text-align: center;
                padding: 10px;
            }
            
            @keyframes modal {
                0% {
                    display: none;
                }
                50%{

                }

                100% {
                   display: block;
                }
            }
            
            .closebutton{
                position: absolute;
                top: 20px;
                right: 20px;
                background-color: transparent;
                color: black;
                
            }
            .icon-cont{
                padding-left: 20px;
                padding-right: 20px;
                width: 100%;
                height: auto;
                display: flex;
                justify-content: start;
                align-items: center;
               
            }
            .content-div{
                width: 100%;
                height: 60%;
                overflow-y: auto;
                padding: 30px;
                text-align: justify;
                animation:content;
                animation-duration: 1s;
                animation-delay: 0.1s;
                animation-fill-mode: forwards;
                opacity: 0;

            }
            @keyframes content{
                0%{
                    opacity: 0;
                }
                50%{
                    opacity: 0.5;
                }
                100%{
                    opacity: 1;
                }
            }
        
        }

        .popupClose {
            width: 50%;
            height: 50%;
            background-color: rgb(128, 128, 128);
            border-radius: 24px;
            animation: modalClose;
            animation-fill-mode: forwards;
            position: relative;
            animation-duration: 0.3s;
            animation-delay: 0.1s;
            transition: all 0.5s ease-in-out;
            transform-style: preserve-3d;
            user-select: initial;
            color: snow;
            font-size: 100%;
            font-family: montserrat;
            text-align: center;
            overflow-y: auto;

            @include XS {
                width: 90%;
                text-align: center;
                padding: 10px;
            }
            @keyframes modalClose {
                0% { 
                }

                100% {
                    font-size: 1px;
                    
                   width: 0;
                   height: 0;
                  
                }
            }
            .closebutton{
                position: absolute;
                top: 20px;
                right: 20px;
                background-color: transparent;
                color: black;
                
            }
            .icon-cont{
                padding-left: 20px;
                padding-right: 20px;
                width: 100%;
                height: auto;
                display: flex;
                justify-content: start;
                align-items: center;
            }
          
            .content-div{
                width: 100%;
                height: 60%;
                overflow-y: auto;
                padding: 30px;
                text-align: justify;
                animation:content;
                animation-duration: 1s;
                animation-delay: 0.1s;
                animation-fill-mode: forwards;
                opacity: 0;
            }
            // .content-div{
            //     width: 100%;
            //     height: 60%;
            //     overflow-y: auto;
            //     padding: 30px;
            //     text-align: justify;
            //     animation:content;
            //     animation-duration: 1s;
            //     animation-fill-mode: forwards;
            //     opacity: 1;

            // }
            // @keyframes content{
            //     0%{
            //         opacity: 1;
            //     }
            //     50%{
            //         opacity: 0.5;
            //     }
            //     100%{
            //         opacity: 0;
            //     }
            // }
       
        }
    }

}