@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.L-landing-container {
	width: 100%;
	margin: 0 auto;
	scroll-snap-align: start;
	overflow-y: scroll;
	background-color: #ffff;

	.L-landing-wrapper {
		width: 100%;
		height: 100vh;

		.L-logo-cont {
			width: 50%;
			height: 7%;


			.L-logo-box {
				animation-name: pulsation;
				animation-iteration-count: infinite;
				animation-duration: 2s;
				.flas-icon{
					width: 20px;
					height: 30px;
				}
			}

			@keyframes pulsation {
				0% {
					transform: scale(0.5);

				}

				50% {
					transform: scale(0.8);
				}

				100% {
					transform: scale(0.5);
				}
			}
		}

		.L-title-container {
			p {
				color: $blue;				
			}

			.L-title-cont {
				
		
			

			}

			.L-page-description {
				p {
					width: 70%;
					margin: 0 auto;
					color:black;
				}
			}
		}
	}
}