@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';


.L-contac-container {
    width: 100%;
    background-color: #ffff;
        .L-contact-wrapper {
            width: 100%;
            height: 100vh;
            flex-direction: row-reverse;
            justify-content: space-evenly;
            padding-top: 20vh;
            @include S {
                height: 200vh;
                flex-direction: column-reverse;
            }
            @include M{
                height:210vh;
          
                flex-direction: column-reverse;
            }
            .L-map-container {
                width: 30%;
                height: 70%;
             
                border-radius: 24px;
                @include S {
                    width: 60%;
                    height: 50%;
                    min-height: 370px;
                }
                @include M {
                   height: auto;
                    width: 100%;
                }

              .hreff{
                width: 100%;
                height: 100%;
                .My-Map{
                    width: 100%;
                    height: 100%;
 
                    @include M {
                        width: 100%;
                        height: 10%;
                        background-size: cover;
                        flex-direction: column-reverse;
                    }
                }
              }
            }
            .L-contact {
                width: 47%;
                height: 75%;
                justify-content: space-between;
                max-height: 800px;

                @include M{
                    width: 100%;
                }
                @include S {
                    width: 100%;

                }

                .L-contact-txt {
                    width: 100%;
                    .L-animation-con{
                        width: 140px;
                        
                        .L-logo-box-contact {
                            animation-name: pulsation;
                            animation-iteration-count: infinite;
                            animation-duration: 2s;
                            width: 25px;
                        }
            
                        @keyframes pulsation {
                            0% {
                                transform: scale(0.5);
            
                            }
            
                            50% {
                                transform: scale(0.8);
                            }
            
                            100% {
                                transform: scale(0.5);
                            }
                        }
                        p {
                            color: $blue;
                          
                           width: 90%;
                           
                        }
                    }
                    p {
                        width: 55%;

                        @include M {
                            width: 100%;
                            text-align: left;
                        }
                    }
             
                }

                .L-contact-inp {
                    width: 100%;
                    height: 20%;
                    @include M {
                        flex-direction: column;
                        height: 30%;
                    }
                    .inp-con {
                        width: 48%;
                        @include M {
                        width: 100%;
                        height: 50%;
                        }
                        .txt {
                            margin-left: 20px;
                            color: $blue;
                            font-weight: bold;
                            font-family: "Montserrat";

                        }
                        .errorName{
                            width: 98%;
                            height: 60%;
                            border-radius: 32px;
                            background-color:#DEDEDE;
                            opacity: 0.5;
                            max-height: 64px;
                            padding-left: 20px;
                            border: 1px solid rgb(255, 17, 17)
                        }
                        .contact-input {
                            width: 98%;
                            height: 60%;
                            border-radius: 32px;
                            background-color:#DEDEDE;
                            opacity: 0.5;
                            max-height: 64px;
                            padding-left: 20px;

                          
                            @include M{
                                margin-top: 5px;
                            }
                            &:focus {
                             
                                border-radius: 32px;
                            }
                        }

                        .contact-input:focus::placeholder {
                            color: transparent;
                        }

                        .contact-input::placeholder {
                            font-family: "Montserrat";
                            color: black;;
                        }
                    }
                }
                .L-textarea {
                    width: 100%;
                    height: 25%;
                    .txt {
                        margin-left: 20px;
                        color: $blue;
                        font-weight: bold;
                        font-family: "Montserrat";

                    }
                    .input {
                        width: 100%;
                        height: 60%;
                        padding: 10px;
                        border-radius: 42px;
                        background-color:#DEDEDE;
                        opacity: 0.5;
                        resize: none;
                        max-height: 128px;
                        padding-left: 20px;
                        padding-top: 20px;

                        @include M{
                            margin-top: 5px;
                            padding-top: 30px;

                        }
                        &:focus {
                            border: 1px solid $blue;
                            border-radius: 42px;
                        }
                    }
                    .input:focus::placeholder {
                        color: transparent;
                    }
                    .input::placeholder {
                        font-family: "Montserrat";
                        font-weight: normal;
                        color: black;   
                        
                    }
                }
                .L-button {
                    font-family: montsserat;
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 14px;
                    height: 10%;
                    max-height: 64px;
                    .send-button {
                        width: 33%;
                        height: 100%;
                        border-radius: 32px;
                        color: snow;
                        background-color: $blue;
                        font-family: montserrat;
                        font-weight: bold;
                        font-size: 12pt;
                        max-height: 50px;
                        @include M {
                            width: 100%;
                        }
                    }
                   .dat{
                    @include M{
                        display: none;
                    }
                    .num{
                        color: #707070;
                        font-family: montserrat;
                        font-weight: 500;
                    }
                   }
                }
            }
        }
    
}