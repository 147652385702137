@import '../../../assets//styles/mixin.scss';
@import '../../../assets//styles/variables.scss';



.L-inside-container {

  .L-inside-news-container {
    width: 100%;
    height: auto;
    .L-inside-wrapper{
      width: 100%;
      height: 100vh;
      padding-top: 150px;
    
     
      @include M{
        flex-direction: column;
        align-items: center;
      }
      .L-inside-menu{
        width: 27%;
        height: 100%;
        position: fixed;
        left: 10;
        display: flex;
        justify-content: center;
        @include M{
          display: none;
        }
        .back-button{
          color: black;
          font-family: montserrat;
          .back-arrow{
            width: 20px;
            height:15px;
          }
        }
      }
      .L-inside-body{
        width: 40%;
        height: 100%;
        position: absolute;
        left: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        @include M{
          position: initial;
          width: 100%;
      
        }
        .back-button{
          color: black;
          display: none;
          @include M{
            display: block;
            position: fixed;
            left: 10px;
            top: 70px;
          }
          .back-arrow{
            width: 20px;
            height: 15px;
          }
        }
        
        .L-inside-data{
          width: 90%;
          height: auto;
          position: absolute;
          top: 35%;
          border-radius: 24px;
          background-color: #ffff;
          padding-bottom: 100px;
          padding: 20px;
          border: 1px solid $blue;

          @include M{
            width: 80%;
          }
          .titl{
            margin-left: 20px;
            font-family: Mont;
          }
          .dangerousinnerhtml{
            padding-bottom: 150px;
            p{
              font-family: montserrat !important;
              margin-top:10px;
            }
            h1,h2,h3{
              font-family: Mont !important;
              margin-top: 20px;
              margin-bottom: 20px;
            }
              a{
              word-wrap: break-word;
                margin-top: 10px;
            }
          }
          .title-box{
            width: 100%;
            height: 15vh;
            border-radius: 24px;
            padding-left:22px;
            display: flex;
            align-items: center;
            .cirqle-avatar{
              width: 70px;
              height: 70px;
              border-radius: 100px;
              border:1px solid $blue ;
              @include L{
              }
              @include M{
               
              }
              .cirqle-image{
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .title{
              width: auto;
              height: 45%;
              margin: 10px; 
              .duration{
                font-size: 12px;
              }
            }
          }
        }
        .image-box{
          width: 100%;
          height: 50%;
          position: relative;
          border-radius: 24px;
          @include M{
            position: fixed;
          

          }
          .myimage{
            width: 100%;
            border-radius: 24px;
            @include M{
              border-bottom-left-radius: 52px ;
              border-bottom-right-radius: 52px ;
              height: 90%;
            }
          }
        }
      }
    }
  }
}
