@import '../../assets/styles/mixin.scss';
@import '../../assets/styles/normalize.scss';

.cirqle{
    transition:visibility 0.3s linear,opacity 0.3s linear;  
    opacity: 0;
    width: 50px;
    height: 50px;
    background-color: $blue;
    border-radius: 50%;
    background-size: 20px;
}