$blue           : #00AAFF;

// Font sizes

// $gago           : 10px;
// $font-size-12   : 12px;
// $font-size-14   : 14px;
// $font-size-16   : 16px;
// $font-size-18   : 18px;
// $font-size-20   : 20px;
// $font-size-24   : 24px;
// $font-size-28   : 28px;
// $font-size-32   : 32px;
// $font-size-40   : 40px;
// $font-size-48   : 48px;


//page withs
$max-width-1700 : 1700px;
$max-width-1600 : 1600px;
$max-width-1400: 1400px;
$max-width-1200 : 1200px;
$max-width-1024 : 1024px;
$max-width-991  : 991px;
$max-width-767  : 767px;
$max-width-576  : 576px;
// transitions
$transition     : 0.3s;


