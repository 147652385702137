.App {
height: auto;
}


.L-scroll-container{

    position: relative;
    top: 50%;
    background-color: red;
    display: flex;
    justify-content: flex-end;

}