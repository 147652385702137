@import '../../assets/styles/mixin.scss';
.L-foother-container{
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 3%;
    @include XS{
      position: initial;
  
    }
    @include M{
 
    }
    .L-foother{
        width: 100%;
        flex-direction: row-reverse;
        @include M{
         display: flex;
         justify-content: center;
         align-items: center;
      }

 
        .L-right-box{
            padding-top: 20px;
            @include M{
              width: 100%;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              flex-direction: row-reverse;
            }
            .foother-href{
              display: none;
              @include M{
                display: block;
                color: black;
                margin: 20px 0;
                font-family: Montserrat;
                font-size: 18px;
                opacity: 0.5;
              }
            }
            .L-sp{
                margin: 20px 0;
                font-family: Montserrat;
					      font-size: 18px;
					      opacity: 0.5;
              }
              .light{
                color: snow;
               

              }
              .dark{
                color: black;
              

              }
        
        }
        .L-left-box{
          @include M{
          display: none;
        }

          .none{
            display: none;
          }
          .blok{
            display: block;
            a{
              text-decoration: none;
              margin: 20px 20px 20px  0;
              color: black;
              opacity: 0.5;
            }
   
          }
         
        }
    
    }

}