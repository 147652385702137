@import "variables";

@mixin XXL {
  @media (max-width : $max-width-1700 ) {
    @content;
  }
}

@mixin XL {
  @media (max-width : $max-width-1600 ) {
    @content;
  }
}

@mixin XS {
  @media (max-width : $max-width-1400 ) {
    @content;
  }
}

@mixin L {
  @media (max-width : $max-width-1024) {
    @content;
  }
}

@mixin M {
  @media (max-width : $max-width-991) {
    @content;
  }
}

@mixin S {
  @media (max-width : $max-width-767) {
    @content;
  }

}




