@import '../../assets/styles/variables';
@import '../../assets/styles/mixin';

.L-meet-container {
	width: 100%;
	background-color: snow;
		.background-about-image{
			width: 100%;
			max-width: 1920px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-repeat: no-repeat;
			background-position: right 7vh;
			.L-meet-wrapper{
				height: 100vh;	
				width: 100%;
				justify-content: center;
				padding-top: 20vh;
				@include M{
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
				
					min-height: 120vh;
					height: auto;
					padding-bottom: 20px;
				}
				@include S{
					align-items: start;
				}
				.L-text-container{
					width: 30%;
					height: 100%;
					flex-direction: column;
					align-items: start;
			
					@include M{
						height: auto;
						width: 100%;
						text-align: center;
						justify-content: center;
						align-items: center;
					}
					@include S{
						align-items:  start;
						text-align: start;
					}
					.Pages-description{
						@include M{
							display: none;
						}
					}
					.Pages-Title{
						line-height:100%;
					}
					
					.L-logo-container {
						display: flex;
						width: 100%;
						align-items:center;
						@include M{
						justify-content: center;
						flex-direction: column;
						}
						@include S{
							flex-direction: row;
							justify-content: start;
						}
						.Pages-greeting{
							color: $blue;
						}
						
						.L-logo-box{
							animation-name: pulsation;
							animation-iteration-count: infinite;
							animation-duration: 2s;
							@keyframes pulsation {
								0% {
									transform: scale(0.5);
								}
								50% {
									transform: scale(0.8);
								}
								100% {
									transform: scale(0.5);
								}
							}
							.flesh-svg{
								width: 20px;
							height: 30px;
							}
						}
					}
					.L-news-description {
						@include M{
							display: none;
						}
					}
				}
				.L-image-container{
					width: 55%;
					display: flex;
					justify-content: flex-end;
					height: 80%;
					
					@include M{
						width: 100%;
						height: 55%;
						justify-content: center;
					}
					@include S{
						justify-content: start;
						
					}
					.L-about-image{
						border-radius: 24px;
						width: 90%;
						@include M{
							width: 70%;
						}
						@include S{
							width:100%;
						}
					}
				}
				.mobile-description{
					display: none;
					@include M{
						display: block;
						text-align: justify;
					}
					@include S{
						text-align: start;
						width: 99%;
						margin-bottom: 15px;
					}
				}
			}
		}

	}