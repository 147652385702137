.menu {

  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow-y: auto;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  .Send {
    width: 200px;
    height: 60px;
    background: linear-gradient(190deg, purple, snow, purple);
    border-radius: 24px;
  }
  .App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
    padding: 12px;
  }
  .wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
    background: linear-gradient(190deg, purple, orange, purple);
    height: 40%;
    width: 70%;
  }
  .editor-class {
    background-color: lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
    height: 20%;
  }
  .toolbar-class {
    border: 1px solid #ccc;
    height: 25%;
  }
}
.sin_in {
  width: 100%;
  height: 100vh;
  background: linear-gradient(45deg, #007991, #78ffd6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  .authorization-container {
    width: 250px;
    height: 300px;
    background: linear-gradient(165deg, #1cefff, pink, #1cefff);
    border-radius: 24px;
    padding: 10px;
    .send_form {
      height: 85%;
      width: 100%;
      input {
        width: 90%;
        height: 40px;
        border-radius: 24px;
      }
      .submit {
        width: 60%;
        height: 30px;
        margin: 30px 25px;
        border-radius: 24px;
        background-color: blue;
      }
    }
  }
}