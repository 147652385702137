@import '../../assets/styles/variables.scss';
@import '../../assets/styles/normalize.scss';
@import '../../assets//styles/mixin.scss';

.L-scroll {
    width: 5px;
    height: 50%;
    max-height: 500px;
    position: fixed;
    top: 25%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.068);
 

    @include M {
        display: none;
    }

    .L-ul {
        display: flex;
        flex-direction: column;
        max-height: 500px;
        height: 0%;

        .blueblok {
            background-color: $blue;
            opacity: 1;
        }

        .blok {
            background-color: snow;
        }

        .none {
            background-color: transparent;
        }

    }
}